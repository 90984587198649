import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { GradientColorOption, GradientTextureOption, IFormChange, IGradientConfig, IModule, IPage } from '@soleran/contracts';
import { GlobalDrawerService } from '../../../global-drawer/global-drawer.service';
import { IModuleFormValue } from '@soleran/ngx-module';
import { toRawGradientStyles } from '@soleran/ngx-layout-utility';
import { DEFAULT_GRADIENT_CONFIG } from '../../constants/default-gradient-config';

@Component({
	selector: 'app-module-modal',
	templateUrl: './module-modal.component.html',
	styleUrl: './module-modal.component.scss',
	encapsulation: ViewEncapsulation.None,
	host: {
		class: 'app-module-modal'
	}
})
export class ModuleModalComponent implements OnInit {
	@Input() module?: IModule;
	@Input() page?: IPage;

	constructor(private _globalDrawer: GlobalDrawerService) { }
	ngOnInit(): void {
		this.formState  = {
			valid: false,
			value: {
				module: this.module,
				gradient: this.page?.gradient ?? DEFAULT_GRADIENT_CONFIG
			}
		};
	}

	formState!: IFormChange<IModuleFormValue>;

	onFormChange(change: IFormChange<IModuleFormValue>) {
		this.formState = change;
	}
	getContainerStyles(formState: any) {
		const gradient = formState.value.gradient;
		if (!gradient) return {};
		const styles = toRawGradientStyles(gradient)
		return styles;
	}
	

	cancel() {
		this._globalDrawer.close();
	}
	submit() {
		this._globalDrawer.close(this.formState.value);
	}
}
