<mat-toolbar>{{ module?.name ?? 'Add Object' }}</mat-toolbar>
<div sol-scroll-shadow-container class="amm-module-form-wrapper" [ngStyle]="getContainerStyles(formState)">
	<mat-card class="amm-card">
		<sol-module-form #form [module]="formState.value.module" [gradient]="formState.value.gradient" (formChange)="onFormChange($event)" class="amm-module-form" />
	</mat-card>
</div>
<mat-toolbar class="amm-bottom-bar">
	<button mat-button (click)="cancel()">Cancel</button>
	<button class="submit-button" mat-button color="primary" [disabled]="!formState.valid" (click)="submit()">
		Save
	</button>
</mat-toolbar>