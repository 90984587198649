import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FIELD_DATA_TYPE_LOOKUP, FieldType, IField, IModule } from '@soleran/contracts';
import { GlobalDrawerService } from '../../../global-drawer/global-drawer.service';

@Component({
	selector: 'app-field-modal',
	templateUrl: './field-modal.component.html',
	styleUrl: './field-modal.component.scss'
})
export class FieldModalComponent implements OnInit {
	@Input({ required: true }) module!: IModule;
	@Input() field?: IField;

	control!: FormControl;

	constructor(private _globalDrawer: GlobalDrawerService) { }

	ngOnInit(): void {
		this._initControl();
	}
	cancel() {
		this._globalDrawer.close();
	}
	submit() {
		const field = this.control.value;
		if (field.type !== FieldType.Formula)
			field.dataType = FIELD_DATA_TYPE_LOOKUP[field.type];
		this._globalDrawer.close(this._globalDrawer.close(field));
	}
	help() {
		const fieldType = this.control.value?.type ?? FieldType.TextBox;
		console.log(`Help requested for Field Modal with Field Type: ${FieldType[fieldType]}`)
	}
	private _initControl() {
		this.control = new FormControl(this.field);
	}
}
